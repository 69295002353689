import React, { useEffect } from 'react';
import {
    isAndroid,
    isIOS
} from "react-device-detect";

function Download() {

  useEffect(() => {
    if (isAndroid) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.apithon.com.tr.sporthon&hl=tr&gl=US";
    }else if(isIOS) {
      window.location.href =
        "https://apps.apple.com/tr/app/sporthon/id1661417338?l=tr";
    } else{
      window.location.href =
        "https://sporthon.apithon.com.tr";
    }
  }, []);

  return (
    <div className="App">
    </div>
  );
}

export default Download;