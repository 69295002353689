import { useParams } from "react-router-dom";

export default function THreeDS() {
  const { id } = useParams();
  return (
    <iframe
      title="Secure"
      srcDoc={atob(id)}
      frameBorder="0"
      allowFullScreen
      style={{ width: "100vw", height: "90vh", position: "relative" }}
    />
  );
}
