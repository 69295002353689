import React, { useEffect } from 'react';
import logo10 from '../../assets/images/logo-10.png';
import logo11 from '../../assets/images/logo-11 2.png';
import StickyMenu from '../../lib/StickyMenu';

function HomeOneHeader({
    darkEnable = false,
    dark,
    className,
}) {
    useEffect(() => {
        StickyMenu();
    });
    return (
        <header className={`appie-header-area appie-sticky ${className || ''}`}>
            <div className="container">
                <div className="header-nav-box">
                    <div className="row align-items-center">
                        <div className="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
                            <div className="appie-logo-box">
                                {darkEnable && dark ? (
                                    <a href="/">
                                        <img src={logo10} alt="" />
                                    </a>
                                ) : (
                                    <a href="/">
                                        <img src={logo11} alt=""/>
                                    </a>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-1 col-sm-1 order-3 order-sm-2">
                            {/* <div className="appie-header-main-menu">
                                <Navigation />
                            </div> */}
                        </div>
                        <div className="col-lg-4  col-md-7 col-sm-6 col-6 order-2 order-sm-3">
                            <div className="appie-btn-box text-right">
                           
                                <a className="login-btn" href="#" >
                                    <i className="fal fa-user" /> Kurumsal Giriş<span className='badge badge-secondary p-1 ml-1'>(Yakında)</span>
                                </a>
                                <a className="main-btn ml-30" href="mailto:bilgi@apithon.com.tr">
                                    İletişim
                                </a>
                                {/* <div
                                    onClick={(e) => action(e)}
                                    className="toggle-btn ml-30 canvas_open d-lg-none d-block"
                                >
                                    <i className="fa fa-bars" />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default HomeOneHeader;
