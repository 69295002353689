import React from 'react';
import thumb from '../../assets/images/surecler.png';

function TrafficHomeOne({ className }) {
    return (
        <section className={`appie-traffic-area pt-140 pb-180 ${className || ''}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="appie-traffic-title ">
                            <span>Sporthon App</span>
                            <h3 className="title mb-3 mt-3">Önce Sizi, Sonra Sporunuzu Düşünüyoruz.</h3>
                            <p>
                                Sporunuzu gerçekleştirmek için her detayı düşünülerek geliştirilmiş güvenilir bir mobil uygulama.
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="appie-traffic-service mb-30">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h5 className="title">Onaylı Antrenörler</h5>
                                    <p>Tüm spor dallarındaki antrenörler uygulama içerisinde kaydolur yetkinlik belgelerini sunar.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="appie-traffic-service item-2 mb-30">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h5 className="title">Fiyat Karşılaştırması</h5>
                                    <p>Antrenörleri görüntülerken hizmet fiyatlandırmalarını inceleyerek diğer antrenörler arasındaki fiyat karşılaştırmasını yapabilirsiniz.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="appie-traffic-service item-3">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h5 className="title">Rezervasyon Takibi</h5>
                                    <p>Oluşturmuş olduğunuz rezervasyonunuzu takip edebilir, antrenörünüzle rezervasyonunuzla ilgili mesajlaşarak tüm sorularınıza yanıt bulabilirsiniz.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="appie-traffic-service item-4">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h5 className="title">Güvenli Ödeme</h5>
                                    <p>3D ödeme altyapısı ile kredi kartı ve banka kartı bilgileriniz İyzico güvencesi ile güvendedir. Rezervasyonunuzla ilgili bir sorun yaşamanız halinde ödemeniz anında geri iade edilir.</p>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="traffic-btn mt-50">
                                    <a className="main-btn" href="#">
                                        Diğer Tüm Bilgiler <i className="fal fa-arrow-right" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="traffic-thumb  ">
                <img
                    className="wow animated fadeInRight"
                    data-wow-duration="2000ms"
                    data-wow-delay="200ms"
                    src={thumb}
                    alt=""
                />
            </div>
        </section>
    );
}

export default TrafficHomeOne;
