import React from 'react';
import logo from '../../assets/images/logo-11 2.png';

function FooterHomeOne({ className }) {
    return (
        <>
            <section className={`appie-footer-area ${className || ''} border-1 border-top mt-5`}>
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-about-widget">
                                <div className="logo mr-5">
                                    <a href="#">
                                        <img src={logo} alt=""  />
                                    </a>
                                </div>
                                <p>
                                    Sporthon APP, bir <a href="https://www.apithon.com.tr">Apithon Software</a> ürünüdür.  
                                </p>
                                <a href="https://www.apithon.com.tr">
                                    Daha fazla bilgi <i className="fal fa-arrow-right" />
                                </a>
                                <div className="social mt-30">
                                    <ul>
                                        <li>
                                            <a href="https://instagram.com/sporthonapp">
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/channel/UCLlR2mQfUJ_1Pqxhv9mpsSw">
                                                <i className="fab fa-youtube" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com">
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-widget-info">
                                <h4 className="title">İletişime Geçin</h4>
                                <ul>
                                    <li>
                                        <a href="mailto:bilgi@apithon.com.tr">
                                            <i className="fal fa-envelope" /> bilgi@apithon.com.tr
                                        </a>
                                    </li>
                                    <li>
                                        <a href="tel:+905465450925">
                                            <i className="fal fa-phone" /> +(90) 546 545 09 25
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fal fa-map-marker-alt" /> Üniversite Mah. İpekyolu Cad. Atatürk Üniversitesi Teknoloji Geliştirme Bölgesi B Blok 2/305 Yakutiye / ERZURUM
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                                <div className="apps-download-btn">
                                    <ul>
                                        <li>
                                            <a rel="noreferrer"  href="https://apps.apple.com/tr/app/sporthon/id1661417338?l=tr" target="_blank">
                                                <i className="fab fa-apple" /> iOS için indirin.
                                            </a>
                                        </li>
                                        <li>
                                            <a rel="noreferrer"  className="item-2" href="https://play.google.com/store/apps/details?id=com.apithon.com.tr.sporthon&hl=tr&gl=US"  target="_blank">
                                                <i className="fab fa-google-play" /> Android için indirin.
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="copyright-text">
                                    <p>Copyright © 2023 <a href='https://apithon.com.tr' className='text-dark' >Apithon Software</a>. <br/> All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FooterHomeOne;
