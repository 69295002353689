import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AboutUs from "./components/AboutUs";
import AboutUsTwo from "./components/AboutUs/AboutUsTwo";
import Contact from "./components/Contact";
import CancelRefund from "./components/Contract/CancelRefund";
import DistanceSelling from "./components/Contract/DistanceSelling";
import SecurtiyPolicy from "./components/Contract/SecurtiyPolicy";
import Download from "./components/Download/Download";

import Error from "./components/Error";
import ScrollToTop from "./components/Helper/ScrollToTop";
import HomeDark from "./components/HomeDark";
import News from "./components/News";
import SingleNews from "./components/News/SingleNews";
import Service from "./components/Service";
import THreeDS from "./components/ThreeDS";
import Result from "./components/ThreeDS/result";

function Routes() {
  // const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  // useEffect(() => {
  //     setTimeout(() => {
  //         setLoading(false);
  //     }, 2000);
  // });
  return (
    <>
      {/* {loading && (
                <div className={`appie-loader ${loading ? 'active' : ''}`}>
                    <Loader />
                </div>
            )} */}
      {/* <div className={`appie-visible ${loading === false ? 'active' : ''}`}> */}
      <div className="appie-visible active">
        <Router basename="/">
          <ScrollToTop>
            <Switch>
              <Route exact path="/" component={HomeDark} />
              <Route exact path="/download" component={Download} />
              <Route exact path="/news" component={News} />
              <Route exact path="/news/single-news" component={SingleNews} />
              <Route exact path="/service" component={Service} />
              <Route exact path="/about-us" component={AboutUs} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/error" component={Error} />
              <Route exact path="/3dsecure/:id" component={THreeDS} />
              <Route exact path="/3dsecure" component={Result} />
              <Route exact path="/about-us-another" component={AboutUsTwo} />
              <Route
                exact
                path="/mesafeli-satis-sozlesmesi"
                component={DistanceSelling}
              />
              <Route path="/gizlilik-ve-guvenlik" component={SecurtiyPolicy} />
              <Route path="/iptal-iade" component={CancelRefund} />
              <Route component={Error} />
            </Switch>
          </ScrollToTop>
        </Router>
      </div>
    </>
  );
}

export default Routes;
