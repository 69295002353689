import React from "react";
import { useLocation } from "react-router-dom";

export default function Result() {
  const location = useLocation();

  React.useEffect(() => {
    // runs on location, i.e. route, change
    console.log("handle route change here", location);
  }, [location]);
  return null;
}
